<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6530_14173)">
      <path d="M2.5 19H0.5C0.5 15.6863 3.18629 13 6.5 13C9.81371 13 12.5 15.6863 12.5 19H10.5C10.5 16.7909 8.70914 15 6.5 15C4.29086 15 2.5 16.7909 2.5 19ZM6.5 12C4.29086 12 2.5 10.2091 2.5 8C2.5 5.79086 4.29086 4 6.5 4C8.70914 4 10.5 5.79086 10.5 8C10.4972 10.208 8.708 11.9972 6.5 12ZM6.5 6C5.4074 6.00111 4.51789 6.87885 4.50223 7.97134C4.48658 9.06383 5.35057 9.9667 6.44269 9.99912C7.53481 10.0315 8.45083 9.1815 8.5 8.09V8.49V8C8.5 6.89543 7.60457 6 6.5 6Z" fill="#31576F"/>
      <circle cx="18" cy="11.5" r="5.5" stroke="#31576F" stroke-width="1.5"/>
      <path d="M18.9832 12.762C18.9832 12.6454 18.9523 12.5408 18.8906 12.4483C18.8289 12.3557 18.7124 12.2717 18.5409 12.1963C18.373 12.1175 18.1261 12.0437 17.8004 11.9752C17.5125 11.9135 17.2468 11.8363 17.0034 11.7438C16.76 11.6512 16.5508 11.5398 16.376 11.4095C16.2011 11.2758 16.064 11.1198 15.9646 10.9416C15.8686 10.7599 15.8206 10.5507 15.8206 10.3142C15.8206 10.0845 15.8703 9.86851 15.9697 9.66624C16.0692 9.46054 16.2131 9.28056 16.4017 9.12629C16.5903 8.96859 16.82 8.84517 17.0908 8.75603C17.3616 8.66347 17.6667 8.61719 18.0061 8.61719C18.4792 8.61719 18.8855 8.69432 19.2249 8.8486C19.5677 9.00287 19.83 9.21542 20.0117 9.48626C20.1934 9.75366 20.2842 10.0553 20.2842 10.3913H18.9266C18.9266 10.2439 18.8923 10.1102 18.8238 9.99021C18.7586 9.87022 18.6575 9.77423 18.5204 9.70224C18.3832 9.62681 18.2101 9.5891 18.001 9.5891C17.8124 9.5891 17.653 9.61996 17.5227 9.68167C17.3959 9.74338 17.2999 9.82394 17.2348 9.92336C17.1696 10.0228 17.1371 10.1325 17.1371 10.2525C17.1371 10.3416 17.1542 10.4222 17.1885 10.4942C17.2262 10.5627 17.2862 10.6262 17.3685 10.6844C17.4508 10.7427 17.5622 10.7959 17.7027 10.8439C17.8467 10.8918 18.0233 10.9381 18.2324 10.9827C18.6472 11.065 19.0089 11.1747 19.3175 11.3118C19.6294 11.4455 19.8728 11.6238 20.0477 11.8466C20.2225 12.0695 20.3099 12.354 20.3099 12.7003C20.3099 12.9471 20.2568 13.1734 20.1505 13.3791C20.0442 13.5813 19.89 13.7579 19.6877 13.9087C19.4854 14.0596 19.2437 14.1779 18.9626 14.2636C18.6815 14.3458 18.3644 14.387 18.0113 14.387C17.5005 14.387 17.0685 14.2961 16.7154 14.1144C16.3623 13.9293 16.0949 13.6962 15.9132 13.4151C15.7315 13.1305 15.6406 12.8357 15.6406 12.5306H16.9417C16.9519 12.7465 17.0102 12.9197 17.1165 13.0499C17.2228 13.1802 17.3565 13.2745 17.5176 13.3328C17.6822 13.3876 17.8553 13.4151 18.037 13.4151C18.2427 13.4151 18.4158 13.3876 18.5564 13.3328C18.6969 13.2745 18.8032 13.1974 18.8752 13.1014C18.9472 13.002 18.9832 12.8888 18.9832 12.762Z" fill="#31576F"/>
    </g>
    <defs>
      <clipPath id="clip0_6530_14173">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
</template>
