import { defineStore, acceptHMRUpdate } from 'pinia'
import baseAxios from '@admin/axios'

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    user: null,
    isInitialized: false,
    isUserUpdated: false,
    applications: [],
  }),

  getters: {
    isDirector: (state) => state.user?.user_role_type.user_role_type_desc === 'director',
  },
  actions: {
    /**
     * Fetches the current user's data from the server.
     *
     * This function performs the following steps:
     * 1. Sends a GET request to the '/users/me' endpoint.
     * 2. Sets the user state to the fetched data.
     * 3. Finally, sets the isInitialized state to true.
     * @async
     */
      async fetchUser() {
        try {
          const { data } = await baseAxios.get('/users/me')
          this.user = data
        } finally {
          this.isInitialized = true
        }
      },

      /**
       * Signs in the user.
       *
       * This function sends a POST request to the '/users/sign_in' endpoint with the provided payload.
       * It then sets the user state to the returned data and returns this data.
       * @async
       * @param {object} payload - The sign in data.
       * @returns {object} The user data.
       */
      async signIn(payload) {
        const { data } = await baseAxios.post('/users/sign_in', payload)
        this.user = data
        return data
      },

      /**
       * Resets the user's password.
       *
       * This function sends a POST request to the '/users/reset_password' endpoint with the provided payload.
       * It then returns the returned data.
       * @async
       * @param {object} payload - The reset password data.
       * @returns {object} The server response data.
       */
      async resetPassword(payload) {
        const { data } = await baseAxios.post('/users/reset_password', payload)
        return data
      },

      /**
       * Changes the user's password.
       *
       * This function sends a PUT request to the '/users/update_password' endpoint with the provided payload.
       * It then sets the user state to null and returns the returned data.
       * @async
       * @param {object} payload - The change password data.
       * @returns {object} The server response data.
       */
      async changePassword(payload) {
        const { data } = await baseAxios.put('/users/update_password', payload)
        this.user = null
        return data
      },

      /**
       * Logs out the user.
       *
       * This function sends a DELETE request to the '/users/sign_out' endpoint.
       * It then sets the user state to null and returns the returned data.
       * @async
       * @returns {object} The server response data.
       */
      async logOut() {
        const { data } = await baseAxios.delete('/users/sign_out')
        this.user = null
        return data
      },

      /**
       * Updates the user's data.
       *
       * This function sends a PATCH request to the '/users' endpoint with the provided payload.
       * It then sets the user state to the returned data, sets the isUserUpdated state to true, and returns the returned data.
       * @async
       * @param {object} payload - The update data.
       * @returns {object} The updated user data.
       */
      async updateUser(payload) {
        const { data } = await baseAxios.patch('/users', payload)
        this.user = data
        this.isUserUpdated = true
        return data
      },

      /**
       * Fetches the user's applications.
       *
       * This function sends a PATCH request to the '/users' endpoint with the provided payload.
       * It then returns the returned data.
       * @async
       * @param {object} payload - The fetch applications data.
       * @returns {object} The fetched applications data.
       */
      async getApplications(payload) {
        const { data } = await baseAxios.get('/applications', { params: payload })
        this.applications = data.items
        return data
      },

      /**
       * Fetches the user's applications.
       *
       * This function sends a PATCH request to the '/users' endpoint with the provided payload.
       * It then returns the returned data.
       * @async
       * @param {object} payload - The fetch applications data.
       * @returns {object} The fetched applications data.
       */
      async getAppeals(payload) {
        const { data } = await baseAxios.get('/appeals', { params: payload })
        return data
      },

      /**
       * Fetches a specific application's data.
       *
       * This function sends a GET request to the '/applications/{id}' endpoint with the provided id.
       * It then returns the returned data.
       * @async
       * @param {string} id - The id of the application to fetch.
       * @returns {object} The fetched application data.
       */
      async getApplication(id) {
        const { data } = await baseAxios.get(`/applications/${id}`)
        return data
      },

      /**
       * Fetches a specific application's data.
       *
       * This function sends a GET request to the '/applications/{id}' endpoint with the provided id.
       * It then returns the returned data.
       * @async
       * @param {string} id - The id of the application to fetch.
       * @returns {object} The fetched application data.
       */
      async getAppeal(id) {
        const { data } = await baseAxios.get(`/appeals/${id}`)
        return data
      },


     /**
      * Fetches the minutes of a specific appeal.
      *
      * This function sends a GET request to the '/appeals/{appeal_id}/appeal_minutes/{id}' endpoint with the provided appeal_id and id.
      * It then returns the returned data.
      * @async
      * @param {string} appeal_id - The id of the appeal to fetch the minutes for.
      * @param {string} id - The id of the minutes to fetch.
      * @returns {object} The fetched appeal minutes data.
      */
      async getAppealMinutes(appeal_id,id) {
        const { data } = await baseAxios.get(`/appeals/${appeal_id}/appeal_minutes/${id}`)
        return data
      },
      /**
       * Fetches a specific application's data.
       *
       * This function sends a GET request to the '/applications/{id}' endpoint with the provided id.
       * It then returns the returned data.
       * @async
       * @param {string} id - The id of the application to fetch.
       * @returns {object} The fetched application data.
       */
      async getApplicationDecision(id) {
        const { data } = await baseAxios.get(`applications/${id}/application_decisions/my`)
        return data
      },
      /**
       * Unchecks the user updated state.
       *
       * This function sets the isUserUpdated state to false.
       */
      uncheckUserUpdatedState() {
        this.isUserUpdated = false
      },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
