import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'

import UserApp from './user/App.vue'
import AdminApp from './admin/App.vue'

import i18n from './i18n'
import routerUser from '@user/router'
import routerAdmin from '@admin/router'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import '@user/assets/scss/main.scss'

import VueAwesomePaginate from 'vue-awesome-paginate'
// import the necessary css file
import 'vue-awesome-paginate/dist/style.css'

import '@/assets/scss/main.scss'

const isAdmin = import.meta.env.VITE_APP_MODE === 'admin'

if (isAdmin) {
  import('@admin/assets/scss/main.scss')
} else {
  import('@user/assets/scss/main.scss')
}

// eslint-disable-next-line no-unused-vars
import * as bootstrap from 'bootstrap'

const app = createApp(isAdmin ? AdminApp : UserApp)
const router = isAdmin ? routerAdmin : routerUser

// Sentry
const replaysSessionSampleRate = import.meta.env
  .VITE_SENTRY_REPLAYS_SESSION_RATE
  ? parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_RATE)
  : 0
const replaysOnErrorSampleRate = import.meta.env.VITE_SENTRY_REPLAYS_ERROR_RATE
  ? parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_ERROR_RATE)
  : 0
const tracesSampleRate = import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE
  ? parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE)
  : 0

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  debug: !!import.meta.env.VITE_SENTRY_DEBUG,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  enabled: !import.meta.env.VITE_SENTRY_DISABLED,

  integrations: [
    // new Sentry.BrowserTracing({
    //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    // }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate,
  replaysOnErrorSampleRate,
})

app
  .use(i18n)
  .use(VueAwesomePaginate)
  .use(createPinia())
  .use(router)
  .component('VueDatePicker', VueDatePicker)
  .mount('#app')
