<template>
  <component :is="route.meta.layout === 'main' ? MainLayout : AuthLayout" />
</template>

<script setup>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import MainLayout from '@user/layout/MainLayout.vue'
import AuthLayout from '@user/layout/AuthLayout.vue'
import { useI18n } from 'vue-i18n'

const route = useRoute()

const { locale } = useI18n({ useScope: 'global' })

onMounted(() => {
  const language = localStorage.getItem('language') ?? 'en'
  locale.value = language
})
</script>
