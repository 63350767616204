<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 23C9.39605 22.9946 8.50092 22.1039 8.49 21H12.49C12.4921 21.2674 12.4411 21.5325 12.34 21.78C12.0777 22.382 11.5418 22.8211 10.9 22.96H10.895H10.88H10.862H10.853C10.7368 22.9842 10.6186 22.9976 10.5 23ZM18.5 20H2.5V18L4.5 17V11.5C4.44732 10.0891 4.76594 8.68913 5.424 7.44C6.07904 6.28151 7.1987 5.45888 8.5 5.18V3H12.5V5.18C15.079 5.794 16.5 8.038 16.5 11.5V17L18.5 18V20Z"
      fill="currentColor"
    />
  </svg>
</template>
