import axios from 'axios'
import i18n from '../../i18n'
import router from '@user/router'

const baseAxios = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/api`,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
})

baseAxios.interceptors.request.use(async (config) => {
  config.headers['Accept-Language'] = i18n.global.locale.value

  return config
})

baseAxios.interceptors.response.use(
  null,
  (error) => {
    if (error.response?.status === 401 && router.currentRoute.value.meta?.auth) {
      router.push('/sign_in');
    }
    return Promise.reject(error);
  }
);


export default baseAxios
