<template>
  <div class="layout">
    <AppHeader class="header" />
    <div class="subheader">
      <div id="subheader"></div>
      <p v-if="storeUser.user" class="subheader_info">
        {{ storeUser.user.employer_full_name + ' | ' }}
        <button class="subheader_btn" @click="logOut">
          {{ $t('header.sign_out') }}
        </button>
      </p>
    </div>
    <div class="layout-body">
      <div class="container-fluid px-0">
        <main class="main">
          <AppAside class="aside" />
          <div class="main_content">
            <router-view />
          </div>
        </main>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script setup>
import AppHeader from '@user/components/UI/AppHeader.vue'
import AppAside from '@user/components/UI/AppAside.vue'
import AppFooter from '@user/components/UI/AppFooter.vue'
import { useUserStore } from '@admin/store/user'
const storeUser = useUserStore()
import { useRouter } from 'vue-router'
const router = useRouter()
/**
 * Logs out the user and navigates to the sign in page.
 * @async
 */
const logOut = async () => {
  await storeUser.logOut()
  router.push('/sign_in')
}
</script>

<style lang="scss" scoped>
.layout {
  &-body {
    display: flex;
    background-color: var(--app-color-white);
  }

  .subheader {
    position: sticky;
    z-index: 10;
    top: 62px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 1280px;
    height: 48px;
    margin: 0 auto;

    background: var(--app-color-white);

    &_info {
      font-size: 18px;
      color: var(--app-color-black);
    }

    &_btn {
      margin: 0;
      padding: 0;

      color: var(--app-color-black);
      text-decoration: underline;

      background: none;
      border: none;

      &:hover {
        text-decoration-thickness: 3px;
      }
    }
  }

  .main {
    display: flex;
    align-items: stretch;
    min-height: calc(100vh - 62px - 48px - 113px);

    &_content {
      flex-grow: 1;
      width: calc(100% - 441px);
      padding-left: 24px;
    }
  }
}
</style>
