<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.39062 14C2.39062 13.4477 2.83834 13 3.39062 13H4.59736V20.0045C4.59736 20.5568 4.14964 21.0045 3.59736 21.0045H3.39063C2.83834 21.0045 2.39062 20.5568 2.39062 20.0045V14Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M8.45553 17.6947H13.6965C14.4582 17.6947 15.0757 17.0772 15.0757 16.3155V16.3155C15.0757 15.5538 14.4582 14.9363 13.6965 14.9363H10.1106L7.35216 13.2812H4.59375V18.7981L5.14543 19.3498L9.00721 21.5565H14.3506L21.1201 17.4166C21.7963 17.003 22.0373 16.1368 21.6719 15.4334V15.4334C21.2884 14.695 20.3797 14.4064 19.6404 14.788L14.1623 17.616"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <circle
      cx="14.8281"
      cy="6.97656"
      r="5.5"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M15.8113 8.23463C15.8113 8.11807 15.7805 8.01351 15.7188 7.92094C15.657 7.82838 15.5405 7.74439 15.3691 7.66896C15.2011 7.59011 14.9542 7.51641 14.6286 7.44784C14.3406 7.38613 14.0749 7.30899 13.8315 7.21643C13.5881 7.12387 13.379 7.01245 13.2041 6.88217C13.0293 6.74847 12.8921 6.59249 12.7927 6.41421C12.6967 6.23252 12.6487 6.02339 12.6487 5.78684C12.6487 5.55715 12.6984 5.34116 12.7979 5.1389C12.8973 4.9332 13.0413 4.75322 13.2298 4.59894C13.4184 4.44124 13.6481 4.31782 13.9189 4.22869C14.1897 4.13613 14.4949 4.08984 14.8343 4.08984C15.3074 4.08984 15.7136 4.16698 16.053 4.32125C16.3958 4.47552 16.6581 4.68808 16.8398 4.95891C17.0215 5.22632 17.1123 5.52801 17.1123 5.86398H15.7548C15.7548 5.71656 15.7205 5.58286 15.6519 5.46287C15.5868 5.34288 15.4856 5.24689 15.3485 5.17489C15.2114 5.09947 15.0382 5.06176 14.8291 5.06176C14.6406 5.06176 14.4811 5.09261 14.3509 5.15432C14.224 5.21603 14.128 5.2966 14.0629 5.39602C13.9978 5.49544 13.9652 5.60514 13.9652 5.72513C13.9652 5.81427 13.9823 5.89483 14.0166 5.96682C14.0543 6.03539 14.1143 6.09881 14.1966 6.15709C14.2789 6.21537 14.3903 6.26851 14.5309 6.31651C14.6748 6.3645 14.8514 6.41079 15.0605 6.45535C15.4753 6.53763 15.837 6.64734 16.1456 6.78447C16.4575 6.91817 16.701 7.09644 16.8758 7.31928C17.0506 7.54212 17.1381 7.82666 17.1381 8.17292C17.1381 8.41976 17.0849 8.64602 16.9786 8.85172C16.8724 9.05399 16.7181 9.23054 16.5158 9.38139C16.3136 9.53223 16.0719 9.65051 15.7907 9.73621C15.5096 9.81849 15.1925 9.85963 14.8394 9.85963C14.3286 9.85963 13.8966 9.76878 13.5435 9.58708C13.1904 9.40196 12.923 9.16883 12.7413 8.88772C12.5596 8.60317 12.4688 8.30834 12.4688 8.00322H13.7698C13.7801 8.2192 13.8383 8.39233 13.9446 8.5226C14.0509 8.65288 14.1846 8.74716 14.3457 8.80544C14.5103 8.86029 14.6834 8.88772 14.8651 8.88772C15.0708 8.88772 15.2439 8.86029 15.3845 8.80544C15.5251 8.74716 15.6313 8.67002 15.7033 8.57403C15.7753 8.47461 15.8113 8.36148 15.8113 8.23463Z"
      fill="currentColor"
    />
  </svg>
</template>
